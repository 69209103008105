body {
  min-height: 100%;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
}
/**CSS DEBUG**/
/* * {
  outline: 1px solid red;
} */
.ant-layout {
  min-height: 100vh;
  min-width: 100vw;
}

.ant-layout-sider {
  position: sticky !important;
  max-width: 350px;
  background-color: #304a70 !important;
  top: 20px;
  bottom: 20px;
  left: 10px;
  box-shadow: 0px 10px 15px -3px rgb(0 0 0 / 28%);
  border-radius: 1rem;
  height: 95vh;
}

.ant-layout-header {
  background-color: #304a70 !important;
  margin: 10px 5px;
  border-radius: 1rem;
}

@media (min-width: 992px) {
  .ant-layout-header {
    display: none;
  }
}

a.main-nav {
  color: #b5c8e3;
  font-size: 16px;
  padding: 0.5em 3rem;
  margin-bottom: 1rem;
  border-left: 5px solid transparent;
}
a.main-nav.active,
a.main-nav:hover {
  border-left: 5px solid #fff;
  color: #fff;
}
.mt-1 {
  margin-top: 1rem;
}

.badge-black {
  background-color: #000000;
  color: #fff;
}
.badge-draft {
  background-color: #aed9e0;
}
.badge-yellow {
  background-color: #fbb02d;
}
.badge-second-yellow {
  background-color: #f3de2c;
}
.badge-red {
  background-color: #d81159;
  color: #fff;
}
.badge-second-red {
  background-color: #8f2d56;
  color: #fff;
}
.badge-green {
  background-color: #38b000;
}
.badge-second-green {
  background-color: #008000;
  color: #fff;
}
.badge-darkest-blue {
  background-color: #1e2e46;
  color: #fff;
}
.badge-dark-blue {
  background-color: #253a5a;
  color: #fff;
}
.badge-blue {
  background-color: #36527c;
  color: #fff;
}
.badge-light-blue {
  background-color: #4066a0;
  color: #fff;
}
.badge-lightest-blue {
  background-color: #6498e7;
  color: #fff;
}
.badge-purple {
  color: #fff;
  background-color: rgb(135, 40, 164);
}
.badge-light-purple {
  background-color: rgb(221, 178, 234);
}
.bottom-sidebar {
  position: absolute;
  bottom: 5%;
  text-align: center;
  width: 100%;
}
.language-switcher {
  background: #b5c8e3;
  width: 80px;
  border: none;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;

  border-radius: 10px;
}
.language-switcher-hr,
.language-switcher-en {
  flex-grow: 1;
  text-align: center;
  padding: 6px 0;
}
.language-switcher-hr {
  border-radius: 10px 0 0 10px;
}
.language-switcher-en {
  border-radius: 0 10px 10px 0;
}
.language-switcher-hr:hover,
.language-switcher-en:hover {
  cursor: pointer;
  background-color: #1a293f;
}

@media (max-width: 476px) {
  .language-switcher-hr,
  .language-switcher-en {
    margin: 0 12px;
    border-radius: 50%;
    background-color: #b5c8e3;
  }
  .ws-info {
    flex-direction: column;
  }
}
.logout-button {
  background: #b5c8e3;
  width: 150px;
  border: none;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  margin-top: 0.5rem;
}
.logout-button-sm {
  background: #b5c8e3;
  border: none;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
}
.logout-button:hover,
.logout-button-sm:hover {
  background-color: #1a293f !important;
  color: #b5c8e3 !important;
}
.d-flex {
  display: flex;
}
.column {
  flex-direction: column;
}
.p-5 {
  padding: 3rem;
}
.p-2 {
  padding: 1rem;
}
.p-1 {
  padding: 0.1rem;
}
.px-0 {
  padding: 0 !important;
}
.px-1 {
  padding-left: 0.375rem !important;

  padding-right: 0.375rem !important;
}
.px-2 {
  padding-left: 1rem !important;

  padding-right: 1rem !important;
}
.pt-3 {
  padding-top: 1rem;
}
.pt-5 {
  padding-top: 2rem;
}
.py-5 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.mt-5 {
  margin-top: 2rem;
}
.bg-white {
  background-color: #fff;
}
.min-vh-100 {
  min-height: 100vh;
}
.min-vh-70 {
  min-height: 70vh;
}
.h-100 {
  height: 100%;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.mx-1 {
  margin-left: 2rem;
  margin-right: 2rem;
}
.mx-2 {
  margin-left: 0.3rem;
  margin-right: 0.3rem;
}
.m-0 {
  margin: 0px !important;
}
.w-100 {
  width: 100%;
}
.mw-100 {
  max-width: 100px !important;
}
.mw-300 {
  max-width: 300px !important;
}
.mw-200 {
  max-width: 200px !important;
}
.logo {
  max-width: 80% !important;
}
.mx-0 {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}
.mt-1 {
  margin-top: 1rem !important;
}
.mt-2 {
  margin-top: 2rem !important;
}
.mb-1 {
  margin-bottom: 1rem !important;
}
.mb-2 {
  margin-bottom: 2rem !important;
}
.mt-3 {
  margin-top: 3rem !important;
}
.mb-3 {
  margin-bottom: 3rem !important;
}
.ms-2 {
  margin-left: 2rem !important;
}
.me-1 {
  margin-right: 1rem !important;
}
.me-2 {
  margin-right: 2rem !important;
}
.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.my-5 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}
.px-3 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}
.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}
.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}
.fs-1 {
  font-size: 3rem !important;
}
.fs-2 {
  font-size: 2rem !important;
}
.fs-3 {
  font-size: 1.2rem !important;
}
.fs-small {
  font-size: 0.7rem !important;
}
.fw-bold {
  font-weight: 700 !important;
}
.text-center {
  text-align: center;
}
.text-end {
  text-align: end;
}
.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.img-fluid {
  max-width: 100%;
  height: auto;
}
.justify-content-end {
  justify-content: end;
}
.justify-content-center {
  justify-content: center;
}
.align-items-center {
  align-items: center;
}
.cursor-pointer {
  cursor: pointer;
}

.container {
  position: relative;
  margin-left: auto;
  margin-right: auto;
}
.text-white {
  color: white !important;
}
.text-gray {
  color: #878787;
}
.of-hidden {
  overflow: hidden;
}
.d-none {
  display: none !important;
}
.px-lg-1 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.login {
  background-color: #304a70;
  padding: 3rem;
  border-radius: 1rem;
  width: 280px;
  box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.075);
}
.login .ant-form .ant-form-item .ant-form-item-label > label {
  color: #fff;
}
.check-in-card {
  width: 480px;
  background-color: #304a70;
  color: #fff;
  top: 100px;
}
.check-in-table {
  background-color: #fff;
  border-radius: 4px;
}
.desktop-qr {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.mobile-qr {
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logged-user {
  color: #b5c8e3;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border: 1px solid #b5c8e3;
  padding: 6px;
  margin: 0 40px;
  border-radius: 18px;
}
.mobile-between {
  justify-content: space-between;
}

@media (max-width: 476px) {
  .check-in-card {
    width: 360px;
  }
  .desktop-qr {
    display: none;
  }
  .mobile-qr {
    display: flex;
  }
  .logged-user {
    margin: 0;
    border: none;
    padding: 0;
    color: #000;
  }
  .print-qr-button {
    display: none;
  }
  .mobile-vertical {
    flex-direction: column;
    margin: 1rem 0 1rem 0;
    h3 {
      margin: 0;
    }
  }
  .mobile-between {
    justify-content: start;
  }
}
@media (min-width: 476px) {
  .container {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media (min-width: 768px) {
  .container {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media (min-width: 992px) {
  .container {
    padding-right: 15px;
    padding-left: 15px;
  }
  .d-lg-none {
    display: none !important;
  }
  .d-lg-block {
    display: block !important;
  }

  .px-lg-1 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (max-width: 992px) {
  .ant-layout {
    display: block;
  }
}
@media (min-width: 1200px) {
  .container {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media (min-width: 476px) {
  .container {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .container {
    width: 900px;
    max-width: 100%;
  }
}
@media (min-width: 992px) {
  .container {
    width: 800px;
    max-width: 100%;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 900px;
    max-width: 100%;
  }
}
@media (min-width: 1400px) {
  .container {
    width: 1040px;
    max-width: 100%;
  }
}
.mobile-menu {
  position: fixed;
  bottom: 10px;
  right: 3px;
  left: 3px;
  z-index: 999;
  min-height: 50px;
  background: #304a70;
  border-radius: 1rem;
  margin-left: 4px;
  margin-right: 4px;
  display: flex;
  align-items: center !important;
  justify-content: space-around !important;
  box-shadow: 0px 10px 15px -3px rgb(0 0 0 / 28%);
}
.mobile-menu-item {
  padding-top: 5px;
}

.mobile-nav {
  color: #b5c8e3;
  width: 90px;
}
.mobile-nav.active,
.mobile-nav:hover {
  color: #fff;
}

.weekend-row .ant-table-cell {
  background-color: #efefef;
}

.rotated-text {
  writing-mode: vertical-rl;
  transform: rotate(180deg);
}

.ant-table-thead > tr > th {
  max-height: 100px;
  white-space: normal;
  word-wrap: break-word;
  text-align: center;
}

.batch-edit-modal {
  min-width: 500px;
}

.batch-edit-button {
  display: none;
}

.floating-edit-button {
  position: fixed;
  background-color: #304a70;
  color: white;
  bottom: 50px;
  right: 70px;
  z-index: 1000;
  display: none;
  padding: 8px 24px 30px;
}

.floating-edit-button.visible {
  display: block;
  opacity: 1;
}

.floating-edit-button.hidden {
  opacity: 0;
}

@media (max-width: 920px) {
  .batch-edit-modal {
    min-width: 80%;
  }

  .floating-edit-button.visible {
    display: none;
  }

  .batch-edit-button.visible {
    display: block;
    opacity: 1;
  }

  .batch-edit-button.hidden {
    opacity: 0;
  }
}

.filter-icon {
  color: white;
  font-size: 13px;
}
.filter-select {
  width: 280px;
}
.link {
  color: #a4b9d9;
  cursor: pointer;
}
.link:hover {
  color: white;
}
@media (max-width: 768px) {
  .filter-select {
    width: 200px;
  }
}

.double-wsEvent-row {
  background-color: #e4e4e4;
  color: #868686;
  cursor: pointer;
}

.split-wsEvent-row {
  background-color: #e4effd;
  cursor: pointer;
}

.ant-popover-inner {
  white-space: pre-line;
}
